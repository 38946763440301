import React from 'react';
import Layout from '@components/Layout';
import styles from './styles.module.scss';
import { Grid, Row, Divider, Col } from '@element-softworks/daintree';
import ScrollTextCard from '@components/ScrollTextCard';
import ThreeImages from '@components/ThreeImages';
import TapTextCard from '@components/TapTextCard';
import { graphql } from 'gatsby';
import SEO from '@components/SEO';
import Header from '@components/Header';
import Img from 'gatsby-image';
import Button from '@components/Button';

const BecomeAPa = ({
    data: {
        page: {
            frontmatter: {
                title,
                description,
                titleTop,
                textTopLeft,
                textTopRight,
                titleBottom,
                textBottomLeft,
                textBottomRight,
                vacancies,
                informationTitle,
                informationText,
                informationButtonLink,
                informationButtonText,
                informationImage,
            },
        },
    },
}) => {
    return (
        <Layout>
            <SEO title={`Become a PA`} />
            <div className={styles.becomePaWrapper}>
                <Header
                    title={title}
                    description={description}
                    graphicName="/images/our-values-liveyou-is-different.svg"
                />

                <Divider />
                <Divider />
                <Divider />

                <div className={styles.becomePaContent}>
                    <Grid>
                        <Row>
                            <Col xs={12}>
                                <div className={styles.topTitle}>
                                    <h2>{titleTop}</h2>
                                    <Divider marg={2} />
                                </div>
                            </Col>
                            <Col xs={12} md={6}>
                                <div className={styles.topDescription}>
                                    {!!textTopLeft && (
                                        <div dangerouslySetInnerHTML={{ __html: textTopLeft }} />
                                    )}
                                </div>
                            </Col>
                            <Col xs={12} md={6}>
                                <div className={styles.topDescription}>
                                    {!!textTopRight && (
                                        <div dangerouslySetInnerHTML={{ __html: textTopRight }} />
                                    )}
                                </div>
                            </Col>
                        </Row>

                        <Divider margin={4} />
                        <Divider margin={4} />

                        <Row>
                            <Col xs={12}>
                                <div className={styles.imagesContainer}>
                                    <img
                                        src="/images/circle.svg"
                                        alt="green circle"
                                        className={styles.firstImage}
                                    />
                                    <img
                                        src="/images/rounded-rectangle.svg"
                                        alt="blue rounded rectangle"
                                        className={styles.secondImage}
                                    />
                                    <img
                                        src="/images/circle.svg"
                                        alt="green circle"
                                        className={styles.lastImage}
                                    />
                                </div>
                            </Col>
                        </Row>

                        <Divider margin={4} />
                        <Divider margin={4} />

                        <Row>
                            <Col xs={12}>
                                <div className={styles.topTitle}>
                                    <h2>{titleBottom}</h2>
                                    <Divider marg={2} />
                                </div>
                            </Col>
                            <Col xs={12} md={6}>
                                <div className={styles.topDescription}>
                                    {!!textBottomLeft && (
                                        <div dangerouslySetInnerHTML={{ __html: textBottomLeft }} />
                                    )}
                                </div>
                            </Col>
                            <Col xs={12} md={6}>
                                <div className={styles.topDescription}>
                                    {!!textBottomRight && (
                                        <div
                                            dangerouslySetInnerHTML={{ __html: textBottomRight }}
                                        />
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Grid>
                </div>

                {!!vacancies && (
                    <div className={styles.jobVacanciesWrapper}>
                        <div className={styles.jobHeading}>
                            <h2>Job Vacancies</h2>
                        </div>
                        <div className={styles.jobVacancies}>
                            {vacancies?.map(({ title, description }) => (
                                <TapTextCard
                                    title={title}
                                    content={description}
                                    jobApplication
                                    key={title}
                                />
                            ))}
                        </div>
                    </div>
                )}

                <Divider />
                <Divider />

                <Grid>
                    <Row>
                        <Col xs={12}>
                            <div className={styles.videoWrapper}>
                                <iframe
                                    width="560"
                                    height="315"
                                    src="https://www.youtube.com/embed/x1ZkAL_aMss"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                />
                            </div>
                        </Col>
                    </Row>
                </Grid>

                <Divider margin={4} />
                <Divider margin={4} />

                <div className={styles.informationContainer}>
                    <Grid>
                        <Row between={'xs'}>
                            <Col md={7} lg={6} xs={12}>
                                <div className={styles.informationContent}>
                                    <h2>{informationTitle}</h2>
                                    <p>{informationText}</p>
                                    <Button
                                        text={informationButtonText}
                                        link={informationButtonLink}
                                    />
                                </div>
                            </Col>
                            <Col md={5} lg={5} xs={12}>
                                <div className={styles.informationImage}>
                                    {/*<Img*/}
                                    {/*    fluid={informationImage?.childImageSharp?.fluid}*/}
                                    {/*    alt="LiveYou logo"*/}
                                    {/*/>*/}
                                    <img src={informationImage?.publicURL} alt="LiveYou logo" />
                                </div>
                            </Col>
                        </Row>
                    </Grid>
                </div>
            </div>
        </Layout>
    );
};

export default BecomeAPa;

export const query = graphql`
    query becomePaPage($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                title: become_pa_title
                description: become_pa_description
                informationTitle: become_pa_information_title
                informationText: become_pa_information_text
                informationButtonText: become_pa_information_button_text
                informationButtonLink: become_pa_information_button_link
                informationImage: become_pa_information_image {
                    publicURL
                }
                titleTop: become_pa_title_top
                textTopLeft: become_pa_text_top_left
                textTopRight: become_pa_text_top_right
                titleBottom: become_pa_title_bottom
                textBottomLeft: become_pa_text_bottom_left
                textBottomRight: become_pa_text_bottom_right
                vacancies: become_pa_vacancies {
                    title: become_pa_vacancies_title
                    description: become_pa_vacancies_description
                }
            }
        }
    }
`;
